<template>
    <div>
        <card-header title="Navigator Options" icon="fa-pencil"/>

        <card-body>
            <quill-editor v-model="trip['app_' + props.type]" ref="descriptionEditor" :options="editorOptions" class="has-background-white content"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import CardFooter from "@/TIER/components/CardFooter";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {CardFooter, ContainerListItem, CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                editorOptions: {},
                trip: {},
                dirty: null
            }
        },
        methods: {
            loadTrip: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId, {force}).then(response => {
                    this.trip = response.data;
                    this.$emit('loading', false);
                });
            },
            submit: function() {
                this.$emit('loading', true);
                let t = {
                    //req fields
                    id: this.trip.id,
                    title: this.trip.title,
                    location: this.trip.location,
                    start_on: this.trip.start_on,
                    end_on: this.trip.end_on
                };

                t['app_' + this.props.type] = this.trip['app_' + this.props.type];
                http.put('/api/trips/' + this.trip.id, t, {force: true}).then(response => {
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
        },
        watch: {
            'props.type': function () {
                this.dirty = null;
                this.loadTrip(true);
            },
            'trip': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
